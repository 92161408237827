<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ title }}
              </h5>
              <p class="text-sm text-body mb-0">
                {{ description }}
                <span v-if="lessonCategories">{{
                  lessonCategories.length
                }}</span>
              </p>
            </div>
          </div>
        </div>

        <v-data-table
           class="bborder"
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-default-header
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th>No</th>
                <th>Нэр</th>
                <th
                  v-for="(h, hIndex) in headers"
                  :class="h.class"
                  :key="hIndex"
                >
                  <span :title="h.text">{{ h.text.toUpperCase() }}</span>
                </th>
              </tr>
            </thead>
          </template>

          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td>
              <td v-for="(header, headerIndex) in headers2" :key="headerIndex">
                <span
                  v-if="
                    header &&
                    header.lessonCategoryRef 
                  "
                  >{{ _getCellScore(props.item, header.lessonCategoryRef) }}</span
                >
              </td>
              <template v-if="goChild == true">
                <td v-for="chld in props.item.children" :key="chld.id">
                  {{ chld["amount"] }}
                </td>
              </template>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls
                  bg-gradient-light
                  py-3
                  px-6
                "
                >Cancel</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                "
                >Ok</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
//
export default {
  components: {},
  data: () => ({
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    defaultItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    lessonCategories: null,
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },

    headerNames: {
      type: Array,
      required: true,
    },
    bodyNames: {
      type: Array,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          // item.avatar = require("@/assets/img/team-9.jpeg");
          list.push(item);
        }
      }
      return list;
    },
  },

  created() {
    this._setupp();

    //this._getResults();
  },
  watch: {
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
    filterSelectedDepartmentClassGroup: function () {
      console.log(this.filterSelectedDepartmentClassGroup);
    },
  },
  methods: {
    _getCellScore(student, lessonCategoryRef) {
      var foundScore = null;
      if (
        lessonCategoryRef &&
        student &&
        student.lessons != undefined &&
        student.lessons != null
      ) {
        for (const lesson of student.lessons) {
          if (
            lesson.categoryRefPath.path ==
            lessonCategoryRef.path
          ) {
            foundScore = lesson.scores[0].finalScore;
            break;
          }
        }
      } 
      return foundScore;
    },
    _setupp() {
      fb.db
        .doc(this.yyschool)
        .collection("departments")
        .where("startYear", "==", 2021)
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("classGroups")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  dep.classGroups.push(cgroup);
                });
              });
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.users2.splice(this.editedIndex, 1);
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.users2.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);

      for (const dep of this.departments) {
        if (
          this.editedItem["department-2021"] &&
          dep.id == this.editedItem["department-2021"].id
        ) {
          this.selectedDepartment = dep;
          this.selectedClassGroup = this.editedItem["classGroup-2021"];
          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== ""
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email) x.email = this.editedItem.email;
        if (this.editedItem.phone) x.phone = this.editedItem.phone;

        if (
          this.selectedClassGroup != null &&
          this.selectedClassGroup != undefined
        ) {
          x["classGroupName-2021"] = this.selectedClassGroup.name;
          x["classGroup-2021"] = this.selectedClassGroup.ref;
        }

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-2021"] = this.selectedDepartment.name;
          x["department-2021"] = this.selectedDepartment.ref;
        }

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 <style>
 .bborder td {
  border-right: 1px solid #bbb !important;
  text-align: center;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
 </style>