<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <MyClassStudentsTableScores
        v-if="students != null"
        title="Ангийн сурагчдын дүн"
        description="Ангийн xичээлийн дүнгийн жагсаалт"
        :path="path"
        addButtonTitle="Сурагч"
        :directGo="true"
        :showAddButton="true"
        :headerNames="headerNames"
        :bodyNames="bodyNames"
        :lessonCategories="[]"
        :students="students"
      ></MyClassStudentsTableScores>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import MyClassStudentsTableScores from "./Widgets/MyClassStudentsTableScores";
import { sync } from "vuex-pathify";
export default {
  components: {
    MyClassStudentsTableScores,
  },
  data: () => ({
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    lessonCategories: null,
    students: null,
    classGroup: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this.path = this.yyschool + "students";
    this.classGroup = this.userData["myClassRef-2021"];
  },
  mounted() {
    fb.db
      .collection(this.path)
      .orderBy("firstName", "asc")
      .where("classGroup-2021", "==", this.classGroup)
      .get()
      .then((querySnapshot) => {
        this.students = [];
        querySnapshot.forEach((doc) => {
          let student = doc.data();
          student.id = doc.id;
          student.ref = doc.ref;
          student.lessons = null;
          student.ref
            .collection("finalGrades")
            .where("currentYear", "==", 2021)
            .onSnapshot((docs) => {
              student.lessons = [];
              docs.forEach((doc) => {
                // score[doc.data().categoryRefPath] = doc.data()
                let lessonScore = doc.data();
                student.lessons.push(lessonScore);
              });
            });
          this.students.push(student);
        });
      });

    fb.db
      .collection(this.yyschool + "xalllessons")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.allLessons = [];
        querySnapshot.forEach((doc) => {
          let categoryLesson = doc.data();
          categoryLesson.id = doc.id;
          categoryLesson.ref = doc.ref;
          categoryLesson.ref
            .collection("departments-2021")
            .doc(this.userData["myClassRef-2021"].parent.parent.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let lesson = doc.data();
                lesson.id = doc.id;
                lesson.ref = doc.ref;
                // categoryLesson.lesson = doc.data();
                // this.allLessons.push(categoryLesson);

                this.headerNames.push({
                  text: categoryLesson.name,
                  value: "lesson.name",
                  sortable: true,
                  lessonCategory: true,
                  lessonCategoryRef: categoryLesson.ref,
                });
              }
            });
        });
      });

    this.headerNames = [
      // {
      //   text: "No",
      //   align: "end",
      //   value: "index",
      //   sortable: true,
      //   width: "1%",
      //   fixed: true,
      // },
      // {
      //   text: "Нэр",
      //   align: "start",
      //   sortable: true,
      //   value: "firstName",
      //   fixed: true,
      // },
    ];
    this.bodyNames = ["firstName"];
  },

  methods: {
    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 